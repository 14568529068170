import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as ffsStyles from "../components/sectionWrap/sectionWrap.module.css"
import SectionWrap from "../components/sectionWrap"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import "../components/layout/layout.css"
import Introtext from "../components/introtext"
import { Helmet } from "react-helmet"
import { SwiperSlide } from "swiper/react"
import SwiperDefault from "../components/swiperDefault"
import { Col, Container, Row } from "react-bootstrap"
import WhatsNext from "../components/whatsNext/whatsnext"
import ListenModule from "../components/listenModule"
import Jobsheader from "../components/header/jobs/index"

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query JobQuery {
      ogimage: file(relativePath: { eq: "images/jobs-og.jpg" }) {
        childImageSharp {
          fixed(height: 400) {
            src
            height
            width
          }
        }
      }
      heropic: file(relativePath: { eq: "images/jobs/header-jobs.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            aspectRatio: 1.7
          )
        }
      }
      wws01: file(relativePath: { eq: "images/jobs/jobs-s-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      wws02: file(relativePath: { eq: "images/jobs/jobs-s-02.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      wws03: file(relativePath: { eq: "images/jobs/jobs-s-03.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: true }
            aspectRatio: 1
          )
        }
      }
      wwb01: file(relativePath: { eq: "images/jobs/candybowl.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      wwb02: file(relativePath: { eq: "images/jobs/DSC07648.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: true }
            aspectRatio: 1
          )
        }
      }
      wwb03: file(relativePath: { eq: "images/jobs/P1210030.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: true }
            aspectRatio: 1
          )
        }
      }
      wwb04: file(relativePath: { eq: "images/jobs/jobs-b-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: ATTENTION, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      wwb05: file(relativePath: { eq: "images/jobs/jobs-b-02.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: true }
            aspectRatio: 1
          )
        }
      }
      wwb06: file(relativePath: { eq: "images/jobs/jobs-b-03.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      wwb07: file(relativePath: { eq: "images/jobs/Firmenlauf-2022.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      wwb08: file(relativePath: { eq: "images/jobs/Sommerparty-2022_1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      wwb09: file(relativePath: { eq: "images/jobs/Sommerparty-2022_2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            aspectRatio: 1
          )
        }
      }                  
      devstack01: file(relativePath: { eq: "images/jobs/devstack01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: false }
            aspectRatio: 1
          )
        }
      }
      devstack02: file(relativePath: { eq: "images/jobs/devstack02.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: WEST, grayscale: true }
            aspectRatio: 1
          )
        }
      }
    }
  `)

  const kununu = (
    <a href="https://kununu.com/de/qmarketing1?rfr=affiliate_widget&utm_content=widget_score_logo_small&utm_medium=affiliate&utm_source=widget" rel="nofollow noopener" target="_blank"><img alt="kununu widget" src="https://widgets.kununu.com/widget_score_logo_small/profiles/37f8f8ed-404a-45b3-8ddc-14948e21fe1a" /></a>
  )

  return (
    <Layout>
      <SEO
        title="Jobs bei Q:marketing"
        description="Kreatives Mastermind oder Programmier-Ass – bei uns bist du richtig. Wenn Du dann noch im Team und trotzdem eigenverantwortlich arbeiten kannst, und eine Prise Humor mitbringst, haben wir ein Match!"
        image={data?.ogimage?.childImageSharp?.fixed}
      /> 

      {/* Header */}
      <Jobsheader />     

      {/* Reasons why */}
      <div id="jobangebote" className="container-fluid">
        <ListenModule
          headline="Aktuelle Jobangebote"
          backgroundclass="whiteback"
          margin={false}
          leftChildren={kununu}
        >

          {/* besuche uns */}
          <a href="/ausbildung" className="banner">
            <SectionWrap className={`p-5 mb-5 standard`} onClick="javascript" pin={false} snap={false} bgCol={"#ff6600"}>
              <div className={`row`}>
                <div className="col-12 text-center">
                  <h3 className="text-white">Eine gute Ausbildung ist das A und Q:</h3>
                  <p className="text-white nopadding nomargin">Hier erfährst du mehr über unser Ausbildungsangebot.</p>
                </div>
              </div>
            </SectionWrap>
          </a>

          <div id="join-widget">        
            <Helmet>
              <script
                defer
                type="text/javascript"
                data-mount-in="#join-widget"
                src="https://join.com/api/widget/bundle/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXR0aW5ncyI6eyJzaG93Q2F0ZWdvcnlGaWx0ZXIiOnRydWUsInNob3dMb2NhdGlvbkZpbHRlciI6dHJ1ZSwic2hvd0VtcGxveW1lbnRUeXBlRmlsdGVyIjp0cnVlLCJsYW5ndWFnZSI6ImRlIiwiam9ic1BlclBhZ2UiOjI1fSwiam9icyI6e30sImRlc2lnbiI6eyJzaG93TG9nbyI6dHJ1ZSwic2hvd0xvY2F0aW9uIjp0cnVlLCJzaG93RW1wbG95bWVudFR5cGUiOnRydWUsInNob3dDYXRlZ29yeSI6dHJ1ZSwiY29sb3JzIjp7IndpZGdldCI6eyJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsImZpbHRlckJvcmRlciI6IiNEMEQ0REEiLCJwYWdpbmF0aW9uIjoiIzJBNzNGRiJ9LCJqb2JDYXJkIjp7InNoYWRvdyI6IiNEMEQ0REEiLCJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsInByaW1hcnlUZXh0IjoiIzM2M0Y0QSIsInNlY29uZGFyeVRleHQiOiIjNUY2ODc1In19fSwidmVyc2lvbiI6MiwiY29tcGFueVB1YmxpY0lkIjoiYzU2NzEzYzljYjNlODhiZDRjZGQ4OTEwNTg4MjY4ZmQiLCJpYXQiOjE2NDE2NTQ5ODUsImp0aSI6IjIyZTJlNWMwLWNiYmItNDZkZS05NzI3LTZkZGYzOGNlZDI3MCJ9.WLTrBZDszHmLl6G7qk8cQ_9KV0bACO9zyC3Qv7sr2OI"
              ></script>
            </Helmet>
          </div>
          <div>
            <p className="pt-5">
              <a href="https://qmarketing.join.com/" target="_blank">
                Nichts dabei?
              </a>{" "}
              Schreibe eine Initiativbewerbung an{" "}
              <a href="mailto:christian.windfeder@Qmarketing.de">
                christian.windfeder@Qmarketing.de
              </a>
            </p>
          </div>
        </ListenModule>
      </div>

      {/* fullwidth swiper mit spalten pro slide */}
      <div className="container-fluid bg-q-grey-on-larger">
        <SectionWrap pin={false} snap={true}>
          <div className={`row ${ffsStyles.vcenterRowContent}`}>
            <div className={`col-12 col-md-6 mx-0 px-0`}>
              <SwiperDefault>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wws01.childImageSharp.gatsbyImageData}
                    alt=""
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb05.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wws02.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                {/* <SwiperSlide>
                  <GatsbyImage
                    image={data.wws03.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>                                 */}
              </SwiperDefault>
            </div>

            <div className={`col-12 col-md-6 mx-0 px-0 py-5 py-md-0`}>
              <Introtext
                skyhead="Wen wir suchen"
                titel={
                  <span style={{ color: "#ff6600" }}>
                    Vielleicht genau dich!
                  </span>
                }
                text="Kreatives Mastermind oder Programmier-Ass – bei uns bist du richtig. Wenn Du dann noch im Team und trotzdem eigenverantwortlich arbeiten kannst, und eine Prise Humor mitbringst, haben wir ein Match!"
                showCta={false}
              />
            </div>
          </div>
        </SectionWrap>
      </div>

      {/* fullwidth swiper mit spalten pro slide */}
      <div className="container-fluid my-0">
        <SectionWrap snap={true}>
          <div className={`row ${ffsStyles.vcenterRowContent}`}>
            <div className={`col-12 col-md-6 mx-0 px-0 py-5 py-md-0`}>
              <Introtext
                skyhead="Was wir bieten"
                titel={
                  <span style={{ color: "#ff6600" }}>
                    Du gibst und du bekommst.
                  </span>
                }
                text={
                  <div className="">
                    <p>
                      Geben und nehmen – die Grundlage einer guten Beziehung.
                      Und deshalb haben wir auch ein paar Benefits für dich:
                      Neben Top-Ausstattung in unserem Office, einer
                      Riesen-Dachterrasse, Kaffee- und Wasserflat sowie
                      Süßigkeiten-Versorgung gibt’s bei uns auch:
                    </p>
                    <Row>
                      <Col>
                        <div className="qliste liniert">
                          <ul>
                            <li>Flexibles Arbeitsmodell</li>
                            <li>Teamgeist</li>
                            <li>Tolle internationale Kunden</li>
                          </ul>
                        </div>
                      </Col>
                      <Col>
                        <div className="qliste liniert">
                          <ul>
                            <li>Office in alter Lederfabrik</li>
                            <li>Aktuelle Hard- und Software</li>
                            <li>
                              &#9749;&nbsp;&nbsp;&#127823;&nbsp;&#127851;{" "}
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
                showCta={false}
              />
            </div>

            <div
              className={`col-12 col-md-6 mx-0 px-0 order-first order-md-last`}
            >
              <SwiperDefault>
              <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb07.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb08.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb09.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>                                                
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb03.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb01.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb02.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb04.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.wwb06.childImageSharp.gatsbyImageData}
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
              </SwiperDefault>
            </div>
          </div>
        </SectionWrap>
      </div>

      {/* 
        devstack fullwidth swiper mit spalten pro slide 
      */}
      <div className="container-fluid my-0 bg-q-grey-on-larger">
        <SectionWrap pin={false} snap={true}>
          <div className={`row ${ffsStyles.vcenterRowContent}`}>
            <div className={`col-12 col-md-6 mx-0 px-0`}>
              <SwiperDefault>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.devstack01.childImageSharp.gatsbyImageData}
                    alt="fdff"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <GatsbyImage
                    image={data.devstack02.childImageSharp.gatsbyImageData}
                    alt="fdff"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>
              </SwiperDefault>
            </div>

            <div className={`col-12 col-md-6 mx-0 px-0 py-5 py-md-0`}>
              <Introtext
                skyhead="Development Stack"
                titel={
                  <span style={{ color: "#ff6600" }}>
                    In Frameworks we trust
                  </span>
                }
                text="Tools, die wir nutzen und mögen."
                link="https://stackshare.io/q-marketing-ag/q-marketing-ag"
                linklabel="StackShare"
                showCtaSecondary={true}
                showCta={false}
              />
            </div>
          </div>
        </SectionWrap>
      </div>

      {/* swiper voll breite */}
      {/* <SectionWrap bgCol={"#ffffff"} className="fullHeight mt-5" pin="false">
        <div className={`row ${ffsStyles.vcenterRowContent}`}>
          <div className={`col-12`}>
            <SwiperDefault>

              <SwiperSlide>
                <GatsbyImage
                  image={data.heropic.childImageSharp.gatsbyImageData}
                  alt="fdff"
                  placeholder="blurred"
                  className="fullHeight"
                />
              </SwiperSlide>

            </SwiperDefault>
          </div>
        </div>
      </SectionWrap> */}

      {/* 
        next
      */}

      <WhatsNext
        text="Lerne schon mal deine Kollegen kennen :-) &rarr;"
        link="/team"
      />
    </Layout>
  )
}

export default Jobs
